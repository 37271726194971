<template>
  <view-container breadcrumbs scroll-fab>
    <toolbar :title="getTitle">
      <v-btn text x-small color="secondary" v-if="editMode" @click="goToMap">
        <v-icon>mdi-map-marker-right</v-icon>
        <span class="text--secondary">{{ $t("srs.map") }}</span>
      </v-btn>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-btn text small color="success" @click="saveAction" :disabled="!valid">
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </toolbar>
    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-3" :style="viewHeight(122)">
        <v-form ref="form" v-model="valid" class="py-5 px-5">
          <form-layout :layout="getLayout">
            <template v-slot:incidentType>
              <v-select
                :items="getTypesByGroup('SRS_INCIDENT_TYPE')"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="id"
                v-model="item.incidentType"
                :label="$t('si.incidentType')"
                dense
              />
            </template>
            <template v-slot:dateTimeOfIncident>
              <v-row no-gutters>
                <v-col cols="12" md="6" sm="12">
                  <date-selector
                    @change="validate()"
                    v-model="item.dateTimeOfIncident"
                    :label="$t('si.dateOfIncident') + ' *'"
                    :rules="
                      $getValidationRules([
                        {
                          rule: 'Required',
                          message: 'This input is required',
                          messageKey: 'vaidations.required',
                        },
                      ])
                    "
                    required
                    dense
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    type="time"
                    v-model="item.timeOfIncident"
                    :label="$t('si.timeOfIncident') + ' *'"
                    :rules="
                      $getValidationRules([
                        {
                          rule: 'Required',
                          message: 'This input is required',
                          messageKey: 'vaidations.required',
                        },
                      ])
                    "
                    required
                    dense
                    class="timeInput"
                  />
                </v-col>
              </v-row>
            </template>

            <template v-slot:areaNa>
              <v-checkbox
                v-model="areaNa"
                :label="$t('srs.na')"
                dense
              />
            </template>

            <!-- <template v-slot:area>
              <v-select
                v-if="item.gcaNgca !== 17"
                :items="getTypesByGroup('SRS_AREA')"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="id"
                v-model="item.area"
                :label="$t('si.area')"
                dense
                :disabled="areaNa"
              />
            </template> -->
            
            <template v-slot:oblast>
              <v-select
                :items="getOblasts"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="pcode"
                validate-on-blur
                v-model="item.oblast"
                :label="$t('si.oblast')"
                :rules="
                  areaNa ? [] :
                  $getValidationRules([
                    {
                      rule: 'Required',
                      message: 'This input is required',
                      messageKey: 'vaidations.required',
                    },
                  ])
                "
                dense
                :disabled="areaNa"
              />
            </template>
            <template v-slot:raion>
              <v-select
                :items="getRaions"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="pcode"
                validate-on-blur
                v-model="item.raion"
                :label="$t('si.raion')"
                :rules="
                  areaNa ? [] :
                  $getValidationRules([
                    {
                      rule: 'Required',
                      message: 'This input is required',
                      messageKey: 'vaidations.required',
                    },
                  ])
                "
                dense
                :disabled="areaNa"
              />
            </template>
            <!-- <template v-slot:hromada>
              <v-select
                v-if="item.gcaNgca !== 17"
                :items="getHromadas"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="pcode"
                validate-on-blur
                v-model="item.hromada"
                :label="$t('si.hromada')"
                :rules="[]"
                dense
                :disabled="areaNa"
              />
            </template>
            <template v-slot:settlement>
              <v-select
                v-if="item.gcaNgca !== 17"
                :items="getSettlements"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="pcode"
                validate-on-blur
                v-model="item.settlement"
                :label="$t('si.settlement')"
                @change="settlementChanged"
                :rules="[]"
                dense
                :disabled="areaNa"
              />
            </template> -->
            <!-- <template v-slot:gcaNgca>
              <v-select
                :items="getTypesByGroup('SRS_GCA_NGCA')"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="id"
                v-model="item.gcaNgca"
                :label="$t('si.gcaNgca', 'GCA/NGCA')"
                dense
              />
            </template> -->
            <template v-slot:russianArea>
              <v-text-field
                v-model="item.russianArea"
                :label="$t('si.russianArea')"
                :rules="
                  areaNa ? [] :
                  $getValidationRules([
                    {
                      rule: 'Required',
                      message: 'This input is required',
                      messageKey: 'vaidations.required',
                    },
                  ])
                "
                dense
                :disabled="areaNa"
              />
            </template>

            <template v-slot:latitude>
              <v-text-field
                v-model="item.latitude"
                :label="$t('si.latitude')"
                dense
              />
            </template>
            <template v-slot:longitude>
              <v-text-field
                v-model="item.longitude"
                :label="$t('si.longitude')"
                dense
              />
            </template>
            <template v-slot:narrativeWereReported>
              <v-textarea
                v-model="item.narrativeWereReported"
                :label="
                  $t('si.narrativeWereReported', 'Narrative / were reported')
                "
                dense
                rows="2"
              />
            </template>
            <template v-slot:typeOfArmsWeaponModel>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsWeaponModel"
                :label="$t('si.weaponModel')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsArtilleryUnidentified>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsArtilleryUnidentified"
                :label="$t('si.artilleryUnidentified')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsMLRS>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsMLRS"
                :label="$t('si.mlrs')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsTank>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsTank"
                :label="$t('si.tank')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsMortar>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsMortar"
                :label="$t('si.mortar')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsMissiles>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsMissiles"
                :label="$t('si.missiles')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsAirStrike>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsAirStrike"
                :label="$t('si.airStrike')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsInterceptedByAirDefence>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsInterceptedByAirDefence"
                :label="$t('si.interceptedByAirDefence')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsClusterMunitions>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsClusterMunitions"
                :label="$t('si.clusterMunitions')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsExplosions>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsExplosions"
                :label="$t('si.explosions')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsHeavyMachineGun>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsHeavyMachineGun"
                :label="$t('si.heavyMachineGun')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsSmallArms>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsSmallArms"
                :label="$t('si.smallArms')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsUAV>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsUAV"
                :label="$t('si.uav')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsERWUXO>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsERWUXO"
                :label="$t('si.erwUxo')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsIEDLandmine>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsIEDLandmine"
                :label="$t('si.iedLandmine')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsChemical>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsChemical"
                :label="$t('si.chemical')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsNuclear>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsNuclear"
                :label="$t('si.nuclear')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsOther>
              <v-text-field
                v-model="item.typeOfArmsOther"
                :label="$t('srs.other')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsOtherValue>
              <v-text-field
                type="number"
                v-model="item.typeOfArmsOtherValue"
                :label="$t('srs.otherValue')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsComment>
              <v-text-field
                v-model="item.typeOfArmsComment"
                :label="$t('srs.comment')"
                dense
              />
            </template>
            <template v-slot:typeOfArmsNA>
              <v-checkbox
                v-model="item.typeOfArmsNA"
                :label="$t('srs.na')"
                dense
              />
            </template>

            <template v-slot:infraPrivateHomes>
              <v-text-field
                type="number"
                v-model="item.infraPrivateHomes"
                :label="$t('si.privateHomes')"
                dense
              />
            </template>
            <template v-slot:infraMultistoreResidentialBuilding>
              <v-text-field
                type="number"
                v-model="item.infraMultistoreResidentialBuilding"
                :label="$t('si.multistoreResidentialBuilding')"
                dense
              />
            </template>
            <template v-slot:infraMedicalFacility>
              <v-text-field
                type="number"
                v-model="item.infraMedicalFacility"
                :label="$t('si.medicalFacility')"
                dense
              />
            </template>
            <template v-slot:infraEducationalFacility>
              <v-text-field
                type="number"
                v-model="item.infraEducationalFacility"
                :label="$t('si.educationalFacility')"
                dense
              />
            </template>
            <template v-slot:infraUnspecifiedCivInfrastructure>
              <v-text-field
                type="number"
                v-model="item.infraUnspecifiedCivInfrastructure"
                :label="
                  $t(
                    'si.unspecifiedCivInfrastructure',
                    'Unspecified Civ. Infrastructure'
                  )
                "
                dense
              />
            </template>
            <template v-slot:infraIndustrialPremises>
              <v-text-field
                type="number"
                v-model="item.infraIndustrialPremises"
                :label="$t('si.industrialPremises')"
                dense
              />
            </template>
            <template v-slot:infraPrivateCompany>
              <v-text-field
                type="number"
                v-model="item.infraPrivateCompany"
                :label="$t('si.privateCompany')"
                dense
              />
            </template>
            <template v-slot:infraAgriculturalObject>
              <v-text-field
                type="number"
                v-model="item.infraAgriculturalObject"
                :label="$t('si.agriculturalObjects')"
                dense
              />
            </template>
            <template v-slot:infraRailroad>
              <v-text-field
                type="number"
                v-model="item.infraRailroad"
                :label="$t('si.railroad')"
                dense
              />
            </template>
            <template v-slot:infraBridge>
              <v-text-field
                type="number"
                v-model="item.infraBridge"
                :label="$t('si.bridges')"
                dense
              />
            </template>
            <template v-slot:infraWaterSupply>
              <v-text-field
                type="number"
                v-model="item.infraWaterSupply"
                :label="$t('si.waterSupply')"
                dense
              />
            </template>
            <template v-slot:infraNuclearPowerPlant>
              <v-text-field
                type="number"
                v-model="item.infraNuclearPowerPlant"
                :label="$t('si.nuclearPowerPlant')"
                dense
              />
            </template>
            <template v-slot:infraElectricPlantStation>
              <v-text-field
                type="number"
                v-model="item.infraElectricPlantStation"
                :label="$t('si.electricPlantStation', 'Electric Plant/Station')"
                dense
              />
            </template>
            <template v-slot:infraPowerLine>
              <v-text-field
                type="number"
                v-model="item.infraPowerLine"
                :label="$t('si.powerLines')"
                dense
              />
            </template>
            <template v-slot:infraDam>
              <v-text-field
                type="number"
                v-model="item.infraDam"
                :label="$t('si.dams')"
                dense
              />
            </template>
            <template v-slot:infraGasLine>
              <v-text-field
                type="number"
                v-model="item.infraGasLine"
                :label="$t('si.gasLines')"
                dense
              />
            </template>
            <template v-slot:infraOther>
              <v-text-field
                v-model="item.infraOther"
                :label="$t('srs.other')"
                dense
              />
            </template>
            <template v-slot:infraOtherValue>
              <v-text-field
                type="number"
                v-model="item.infraOtherValue"
                :label="$t('srs.otherValue')"
                dense
              />
            </template>
            <template v-slot:infraNA>
              <v-checkbox v-model="item.infraNA" :label="$t('srs.na')" dense />
            </template>

            <template v-slot:militaryAmmunitionDepot>
              <v-text-field
                type="number"
                v-model="item.militaryAmmunitionDepot"
                :label="$t('si.ammunitionDepot')"
                dense
              />
            </template>
            <template v-slot:militaryMilitaryBase>
              <v-text-field
                type="number"
                v-model="item.militaryMilitaryBase"
                :label="$t('si.militaryBase')"
                dense
              />
            </template>
            <template v-slot:militaryOther>
              <v-text-field
                v-model="item.militaryOther"
                :label="$t('srs.other')"
                dense
              />
            </template>
            <template v-slot:militaryOtherValue>
              <v-text-field
                type="number"
                v-model="item.militaryOtherValue"
                :label="$t('srs.otherValue')"
                dense
              />
            </template>
            <template v-slot:militaryNA>
              <v-checkbox
                v-model="item.militaryNA"
                :label="$t('srs.na')"
                dense
              />
            </template>

            <template v-slot:peopleCivilianFatalities>
              <v-text-field
                type="number"
                v-model="item.peopleCivilianFatalities"
                :label="$t('si.civilianFatalities')"
                dense
              />
            </template>
            <template v-slot:peopleCivilianInjuries>
              <v-text-field
                type="number"
                v-model="item.peopleCivilianInjuries"
                :label="$t('si.civilianInjuries')"
                dense
              />
            </template>
            <template v-slot:peoplePolSESUFatalities>
              <v-text-field
                type="number"
                v-model="item.peoplePolSESUFatalities"
                :label="$t('si.polSESUFatalities', 'Pol, SESU Fatalities')"
                dense
              />
            </template>
            <template v-slot:peoplePolSESUInjured>
              <v-text-field
                type="number"
                v-model="item.peoplePolSESUInjured"
                :label="$t('si.polSESUInjured', 'Pol, SESU Injured')"
                dense
              />
            </template>
            <template v-slot:peopleOther>
              <v-text-field
                v-model="item.peopleOther"
                :label="$t('srs.other')"
                dense
              />
            </template>
            <template v-slot:peopleOtherValue>
              <v-text-field
                type="number"
                v-model="item.peopleOtherValue"
                :label="$t('srs.otherValue')"
                dense
              />
            </template>
            <template v-slot:peopleNA>
              <v-checkbox v-model="item.peopleNA" :label="$t('srs.na')" dense />
            </template>

            <template v-slot:source>
              <v-text-field
                v-model="item.source"
                :label="$t('si.source')"
                dense
              />
            </template>
            <template v-slot:reportedBy>
              <v-text-field
                v-model="item.reportedBy"
                :label="$t('si.reportedBy')"
                dense
              />
            </template>
            <template v-slot:shiftSign>
              <v-select
                :items="getTypesByGroup('SRS_SHIFT_SIGN')"
                :item-text="getLanguage === 'en' ? 'name' : 'nameUk'"
                item-value="id"
                v-model="item.shiftSign"
                :label="$t('si.shiftSign')"
                dense
              />
            </template>
            <template v-slot:shiftDate>
              <date-selector
                :width="300"
                @change="validate()"
                v-model="item.shiftDate"
                :label="$t('si.shiftDate')"
                dense
              />
            </template>
            <template v-slot:shift>
              <v-text-field
                type=""
                v-model="item.shift"
                :label="$t('si.shift')"
                dense
              />
            </template>
          </form-layout>

          <br />
          <form-bar
            :valid="valid"
            @save="saveAction"
            @reset="reset"
            @validate="$refs.form.validate()"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toolbar from "@/common/components/Toolbar";
import FormLayout from "@/common/layouts/FormLayout";
import ViewContainer from "@/common/layouts/ViewContainer";
import FormBar from "@/common/components/FormBar";
import DateSelector from "@/common/components/DateSelector";
import moment from "moment";

export default {
  name: "IncidentForm",
  components: {
    Toolbar,
    ViewContainer,
    FormBar,
    FormLayout,
    // eslint-disable-next-line vue/no-unused-components
    DateSelector,
  },
  data() {
    return {
      areaNa: false,
      item: {},
      timeOfIncident: null,
      valid: false,
      originalState: { id: 0 },
      loaded: {
        admin1: false,
        admin2: false,
        admin3: false,
        admin4: false,
      },
    };
  },
  mounted() {
    this.loadTypes(true);
    this.loadAdmin1Areas().then(() => (this.loaded.admin1 = true));
    this.loadAdmin2Areas().then(() => (this.loaded.admin2 = true));
    this.loadAdmin3Areas().then(() => (this.loaded.admin3 = true));
    this.loadAdmin4Areas().then(() => (this.loaded.admin4 = true));

    if (this.$route.params && this.$route.params.item) {
      this.item.incidentType = this.$route.params.item.incidentType; //index based to id based
      this.item.narrativeWereReported = this.$route.params.item.message;
      this.item.dateTimeOfIncident = moment(
        this.$route.params.item.date
      ).format("yyyy-MM-DD");
      this.item.timeOfIncident = moment(this.$route.params.item.date).format(
        "HH:mm"
      );
    }

    if (this.editMode) {
      this.loadIncident(this.idParam).then(() => {
        this.loadItem(this.getIncidentById(this.idParam));
      });
    }
  },
  computed: {
    ...mapGetters("ui", ["getLanguage"]),
    ...mapGetters("types", ["getTypesByGroup"]),
    ...mapGetters("incidents", ["getIncidentById"]),
    ...mapGetters("areas", [
      "getAdmin1Areas",
      "getAdmin2Areas",
      "getAdmin3Areas",
      "getAdmin4Areas",
    ]),

    idParam() {
      return parseInt(this.$route.params.incidentId);
    },
    editMode() {
      return this.idParam > 0;
    },
    getTitle() {
      if (this.editMode) {
        return this.$t("srs.incident") + ": " + this.$t("general.edit");
      } else {
        return this.$t("srs.incident") + ": " + this.$t("general.new");
      }
    },

    getLayout() {
      return [
        {
          type: null,
          title: "Report Initial Data",
          titleKey: "si.reportInitialData",
          rows: [
            {
              columns: [
                { name: "incidentType", width: 6 },
                { name: "dateTimeOfIncident", width: 6 },
              ],
            },
            // {
            //   columns: [{ name: "gcaNgca", width: 6 }],
            // },
            { columns: [{ name: "narrativeWereReported", width: 12 }] },
          ],
        },
        {
          type: "card",
          title: "location",
          titleKey: "srs.location",
          rows: [
            {
              columns: [
                { name: "areaNa", width: 6 },
                // { name: "area", width: 6 },
              ],
            },
            {
              columns: [
                { name: "oblast", width: 6 },
                { name: "raion", width: 6 },
              ],
            },
            {
              columns: [{ name: "russianArea", width: 6 }]
            },
            {
              columns: [
                { name: "latitude", width: 6 },
                { name: "longitude", width: 6 },
              ],
            },
          ],
        },
        {
          type: "card",
          title: "typeOfArms",
          titleKey: "si.typeOfArms",
          rows: [
            {
              columns: [
                { name: "typeOfArmsWeaponModel", width: 4 },
                { name: "typeOfArmsArtilleryUnidentified", width: 4 },
                { name: "typeOfArmsMLRS", width: 4 },
              ],
            },
            {
              columns: [
                { name: "typeOfArmsTank", width: 4 },
                { name: "typeOfArmsMortar", width: 4 },
                { name: "typeOfArmsMissiles", width: 4 },
              ],
            },
            {
              columns: [
                { name: "typeOfArmsAirStrike", width: 4 },
                { name: "typeOfArmsInterceptedByAirDefence", width: 4 },
                { name: "typeOfArmsClusterMunitions", width: 4 },
              ],
            },
            {
              columns: [
                { name: "typeOfArmsExplosions", width: 4 },
                { name: "typeOfArmsHeavyMachineGun", width: 4 },
                { name: "typeOfArmsSmallArms", width: 4 },
              ],
            },
            {
              columns: [
                { name: "typeOfArmsUAV", width: 4 },
                { name: "typeOfArmsERWUXO", width: 4 },
                { name: "typeOfArmsIEDLandmine", width: 4 },
              ],
            },
            {
              columns: [
                { name: "typeOfArmsChemical", width: 4 },
                { name: "typeOfArmsNuclear", width: 4 },
              ],
            },
            {
              columns: [
                { name: "typeOfArmsOther", width: 4 },
                { name: "typeOfArmsOtherValue", width: 4 },
                { name: "typeOfArmsComment", width: 4 },
              ],
            },
            {
              columns: [{ name: "typeOfArmsNA", width: 4 }],
            },
          ],
        },
        {
          type: "card",
          title: "Civiian Infrastructure",
          titleKey: "si.civilianInfrastructure",
          rows: [
            {
              columns: [
                { name: "infraPrivateHomes", width: 4 },
                { name: "infraMultistoreResidentialBuilding", width: 4 },
                { name: "infraMedicalFacility", width: 4 },
              ],
            },
            {
              columns: [
                { name: "infraEducationalFacility", width: 4 },
                { name: "infraUnspecifiedCivInfrastructure", width: 4 },
                { name: "infraIndustrialPremises", width: 4 },
              ],
            },
            {
              columns: [
                { name: "infraPrivateCompany", width: 4 },
                { name: "infraAgriculturalObject", width: 4 },
                { name: "infraRailroad", width: 4 },
              ],
            },
            {
              columns: [
                { name: "infraBridge", width: 4 },
                { name: "infraWaterSupply", width: 4 },
                { name: "infraNuclearPowerPlant", width: 4 },
              ],
            },
            {
              columns: [
                { name: "infraElectricPlantStation", width: 4 },
                { name: "infraPowerLine", width: 4 },
                { name: "infraDam", width: 4 },
              ],
            },
            {
              columns: [
                { name: "infraGasLine", width: 4 },
                { name: "infraOther", width: 4 },
                { name: "infraOtherValue", width: 4 },
              ],
            },
            { columns: [{ name: "infraNA", width: 4 }] },
          ],
        },
        {
          type: "card",
          title: "Military",
          titleKey: "si.military",
          rows: [
            {
              columns: [
                { name: "militaryAmmunitionDepot", width: 6 },
                { name: "militaryMilitaryBase", width: 6 },
              ],
            },
            {
              columns: [
                { name: "militaryOther", width: 6 },
                { name: "militaryOtherValue", width: 6 },
              ],
            },
            {
              columns: [{ name: "militaryNA", width: 6 }],
            },
          ],
        },
        {
          type: "card",
          title: "Casualties",
          titleKey: "si.casualties",
          rows: [
            {
              columns: [
                { name: "peopleCivilianFatalities", width: 6 },
                { name: "peopleCivilianInjuries", width: 6 },
              ],
            },
            {
              columns: [
                { name: "peoplePolSESUFatalities", width: 6 },
                { name: "peoplePolSESUInjured", width: 6 },
              ],
            },
            {
              columns: [
                { name: "peopleOther", width: 6 },
                { name: "peopleOtherValue", width: 6 },
              ],
            },
            {
              columns: [{ name: "peopleNA", width: 4 }],
            },
          ],
        },
        {
          type: "card",
          title: "Source",
          titleKey: "si.source",
          rows: [
            {
              columns: [
                { name: "source", width: 6 },
                { name: "reportedBy", width: 6 },
              ],
            },
            {
              columns: [
                { name: "shiftSign", width: 6 },
                { name: "shiftDate", width: 6 },
              ],
            },
          ],
        },
      ];
    },

    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    isAdmin() {
      return this.$hasRole("ADMIN");
    },

    getOblasts() {
      return this.getAdmin1Areas.filter((f) => f.admin0 === "RU");
    },

    getRaions() {
      return this.item.oblast && this.notEmptyArray(this.getAdmin2Areas)
        ? this.getAdmin2Areas.filter((f) => f.admin1PCode === this.item.oblast)
        : [
            {
              name: "Please select an oblast type to populate settlement list",
              pcode: null,
            },
          ];
    },
    getHromadas() {
      return this.item.raion && this.notEmptyArray(this.getAdmin3Areas)
        ? this.getAdmin3Areas.filter((f) => f.admin2PCode === this.item.raion)
        : [
            {
              name: "Please select an oblast type to populate settlement list",
              pcode: null,
            },
          ];
    },

    getSettlements() {
      return this.item.hromada && this.notEmptyArray(this.getAdmin4Areas)
        ? this.getAdmin4Areas.filter((f) => f.admin3PCode === this.item.hromada)
        : [
            {
              name: "Please select an oblast type to populate settlement list",
              pcode: null,
            },
          ];
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("incidents", ["loadIncident", "saveIncident"]),
    ...mapActions("areas", [
      "loadAdmin1Areas",
      "loadAdmin2Areas",
      "loadAdmin3Areas",
      "loadAdmin4Areas",
    ]),

    loadItem(item) {
      this.item = item;
      this.item.incidentType = this.item.incidentType?.id;
      // this.item.gcaNgca = this.item.gcaNgca?.id;
      // this.item.area = this.item.area?.id;
      this.item.shiftSign = this.item.shiftSign?.id;
      this.item.timeOfIncident = this.item.dateTimeOfIncident
        ? this.item.dateTimeOfIncident.split("T")[1]
        : "00:00:00";
      Object.assign(this.originalState, this.item);
    },

    goToMap() {
      this.$router.push({
        name: "map",
      });
    },

    settlementChanged(settlement) {
      const s = this.getAdmin4Areas.find((f) => f.pcode === settlement);
      console.log(s);
      if (s && !this.item.latitude && !this.item.longitude) {
        this.item.latitude = s.latitude;
        this.item.longitude = s.longitude;
      }
    },

    saveAction() {
      this.validate();

      this.item.gcaNgca = 17;
      this.item.area = null;
      this.item.hromada = null;
      this.item.settlement = null;

      setTimeout(() => {
        if (this.valid) {
          const timeParts = this.item.dateTimeOfIncident.split("T");
          // yyyy-MM-dd'T'HH:mm:ss.SSSX
          const dateTimeOfIncident = `${timeParts[0]}T${this.item.timeOfIncident}`;
          this.item = { ...this.item, dateTimeOfIncident };

          const params = {
            item: this.item,
            editMode: this.editMode,
          };
          this.saveIncident(params).then(() => {
            this.$router.go(-1);
          });
        }
      }, 500);
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style>
.timeInput input {
  height: 26px;
}
</style>